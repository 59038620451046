.slider{
    height: 30rem !important;
    object-fit: contain;

}

.img{
    height: 100%;
    /* object-fit: fill; */
    object-fit: contain;
}


@media only screen and (max-width:748px){
    .slider{
        height: 20rem !important;
        object-fit: contain;
    }
}