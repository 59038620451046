.container{
    height: 100%;
}

.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    color: white;
    font-family: "DM Serif Text", serif;
    
    background: linear-gradient(90deg, #001823, #00192a, #001b31, #001b38, #001c3f, #041b45, #111a4a, #1e184e);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.logo{
    font-size: 1.5rem;
}

.main{
    /* padding-top: rem; */
    height: 150vh;
    min-height: 100%;
    padding: 0rem 2rem;
}

.player{
    background-color: black;
    /* height: 50rem !important; */
}

.videoList{
    padding: 2rem 1rem;
}

.title{
    font-size: 1.5rem;
    color: white;
    font-family:"DM Serif Text", serif;
    
}

@media only screen and (max-width:576px){
    .nav{
        padding: 1rem;
    }

    
.main{
    /* padding-top: rem; */
    height: 100%;
    min-height: 100vh;
}
}