.container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 0.5rem;
    /* width: 100%; */
}

.card{
    height: 14rem;
    background: black;
    border-radius: 10px;
    padding: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
    /* width: 11.5rem; */

}

.img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 100%;
    width: 100%;
    
}

.card_img{
    position: relative;
    height: 85%;
}


.btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(8, 1, 23, 0.911);
    color: white;
    border: none;
    padding: 0.25rem;
    width: 50%;
    font-size: 1.25rem;
    border-radius: 5px;
    font-family: "DM Serif Text", serif;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.15rem;
  }


  .card_img:hover .btn {
    visibility: visible;
    opacity: 1;
  }

  
  .name {
    width: 10rem; 
    overflow: hidden; /* Ensures the text doesn't overflow the container */
    text-overflow: ellipsis; /* Adds ellipsis when the text overflows */
    white-space: nowrap; /* Prevents text wrapping to the next line */
    font-size: 16px; /* Optional: Adjust font size */
    line-height: 1.2; /* Optional: Adjust line height for readability */
    color: white;
    text-align: center;

  }


  @media only screen and (max-width: 1028px) {
    .container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 0.5rem;
    }

    .card{
      /* width: 80%; */
    }

    
  }


  @media only screen and (max-width:748px){
    .container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.5rem;
    }

    .card{
      /* width: 70%; */
    }
  }

  @media only screen and (max-width:576px){
    .card{
        height: 10rem;
    }

    .container{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
  }
    .card{
      /* width: 7rem; */
    }
    .name {
      width: 6rem; 
    }

    .btn{
      width: 80%;
    }
    
}