

.container{
    width: 100%;
    position: fixed;
    z-index: 99;
}

.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    color: white;
    
    font-family: "DM Serif Text", serif;
    background: linear-gradient(90deg, #001823, #00192a, #001b31, #001b38, #001c3f, #041b45, #111a4a, #1e184e);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.logo{
    /* font-family: "DM Serif Text", serif; */
    /* font-size: 2rem; */
}

.logo:hover{
    color: rgb(251, 235, 6) ;
}

.categories{
    display: flex;
    gap: 1rem;
}

.category{
    padding: 0.2rem 0.5rem;
    font-size: 1.25rem;
    /* width: 5rem; */
}

.category:hover{
    border: 1px solid grey;
    border-radius: 10px;
    color: rgb(251, 235, 6);
}

.icon{
    display: none;
}


@media only screen and (max-width:748px){
    .categories{
        display: none;
    }

    .icon{
        display: block;
    }

}