.sidebar{
    background-color: black;
    color: white;
    min-height: 100vh;
    padding: 1rem;
    font-family: "DM Serif Text", serif;
}

.title{
    font-size: 2rem;
}

.list{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
}

.list_item{
    font-size: 1.5rem;
    padding: 0.5rem;
    width: 80%;
}


.list_item:hover{
    border: 1px solid grey;
    border-radius: 10px;
    color: rgb(251, 235, 6);
    cursor: pointer;
}