.category_div{
    padding: 1rem;
    
}


.main{
    padding: 4rem 0rem;
}

.carousel{
    padding: 1rem 2rem;
}

