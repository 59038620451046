.title_div{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    font-family: "DM Serif Text", serif;
    color: white;
}

.title_line{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
}

.title{
    font-size: 1.75rem;
    max-width: 13rem;
    overflow: hidden; /* Ensures the text doesn't overflow the container */
    text-overflow: ellipsis; /* Adds ellipsis when the text overflows */
    white-space: nowrap;
}

.line{
    /* padding: 0.2rem; */
    background-color: yellow;
    border: 1px ridge grey;
    height: 1px;
    width: 30%;

}

.slide{
    height: 15rem !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: black;
    border-radius: 10px;
}

.img{
    height: 100%;
    border-radius: 10px;
    /* object-fit: contain; */
    position: relative;
}


.btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(8, 1, 23, 0.911);
    color: white;
    border: none;
    padding: 0.25rem;
    width: 30%;
    font-size: 1.25rem;
    border-radius: 5px;
    font-family: "DM Serif Text", serif;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
  }
  
  .slide:hover .btn {
    visibility: visible;
    opacity: 1;
  }


  @media only screen and (max-width:576px){
    .btn{
        visibility: visible;
    }

    .title_div{
        padding: 1rem 0.5rem;
    }
  }