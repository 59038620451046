@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Manrope:wght@200..800&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Signika+Negative:wght@300..700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:linear-gradient(90deg, #001823, #00192a, #001b31, #001b38, #001c3f, #041b45, #111a4a, #1e184e); ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  /* height: 20rem !important; */
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.player{
  /* height: 20rem !important; */
}

